<template>
  <div v-if="surveysAverage" class="tw-my-8 tw-p-4">
    <div class="tw-pb-4 tw-flex tw-gap-4 tw-items-center">
      <img
        alt="score"
        :src="scores[Math.floor(surveysAverage)].icon"
        class="tw-h-10"
      />
      <h2 class="tw-my-0">
        Gemiddelde score: {{ surveysAverage }}
        ({{ surveys.count }} {{ surveys.count === 1 ? 'CSAT-bevraging' : 'CSAT-bevragingen' }})
      </h2>
    </div>

    <DataTable
      v-bind="surveys"
      :loading="loading"
      :headers="headers"
      :infinite-scroll="true"
      @infiniteLoad="infiniteLoad"
    >
      <template #item-score="{ item }">
        <img
          :title="`Score: ${item.score}`"
          :alt="`Score: ${item.score}`"
          :src="scores[item.score].icon"
          class="tw-h-5"
        />
      </template>
      <template #item-completed_on="{ item }">
        {{ item.completed_on | date-day }}
      </template>
      <template #item-property="{ item }">
        <router-link
          :to="{ name: 'PropertyDetails', params: { id: item.property.id } }"
          target="_blank"
        >
          {{ item.property.reference }}
        </router-link>
      </template>
      <template #item-contact="{ item }">
        <router-link
          :to="{ name: 'ContactDetails', params: { id: item.contact.id } }"
          target="_blank"
        >
          {{ item.contact.display_name }}
        </router-link>
      </template>
      <template #item-collaborator_comments="{ item }">
        <SurveysAddEditComment
          :survey="item"
          @submitted="commentSubmitted"
        >
          <template #summary>
            {{ item.collaborator_comments.length }}
            {{ item.collaborator_comments.length === 1 ? 'opmerking' : 'opmerkingen' }}
          </template>
        </SurveysAddEditComment>
      </template>
    </DataTable>
  </div>
</template>

<script>
import scores from '@/utils/surveyScores'

import DataTable from '@/components/DataTable'
import SurveysAddEditComment from '@/components/SurveysAddEditComment.vue'

import { getSurveys, getSurveysAverage } from '@/services/surveys'

export default {
  name: 'DashboardSurveys',
  components: {
    DataTable,
    SurveysAddEditComment
  },
  constants: {
    scores
  },
  data () {
    return {
      surveys: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      surveysAverage: null,
      commentForm: {}
    }
  },
  computed: {
    headers () {
      return [
        { value: 'score', text: 'Score' },
        { value: 'completed_on', text: 'Ingediend op' },
        { value: 'type.name', text: 'Bevraging' },
        { value: 'property', text: 'Pand' },
        { value: 'contact', text: 'Contact' },
        { value: 'comment', text: 'Opmerking contact' },
        { value: 'collaborator_comments', text: 'Opmerkingen van de medewerker' }
      ]
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      this.loading = true
      const params = { for_collaborator: true }
      const [surveys] = await Promise.all([
        this.fetchSurveys({ params }),
        this.fetchSurveysAverage(params)
      ])
      this.surveys = surveys
      this.loading = false
      return surveys
    },
    async fetchSurveysAverage (params) {
      const response = await getSurveysAverage(params)
      this.surveysAverage = response.data?.average
      return response.data
    },
    async fetchSurveys (payload = {}) {
      const response = await getSurveys(payload)
      return response.data
    },
    async infiniteLoad ($infinite, next) {
      try {
        if (!next) {
          // No more data and state is loaded
          if (this.surveys.results.length) $infinite.loaded()
          return $infinite.complete()
        }
        const surveys = await this.fetchSurveys({ url: next })
        const results = [...this.surveys.results, ...surveys.results]
        this.surveys = { ...surveys, results }
        $infinite.loaded()
        return surveys
      } catch (error) {
        $infinite.error()
        console.error(error)
      }
    },
    async commentSubmitted () {
      const surveys = await this.fetchSurveys({ for_collaborator: true })
      this.surveys = surveys
      return surveys
    }
  }
}
</script>
