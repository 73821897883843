<template>
  <div>
    <PageHeader title="Dashboard" />
    <transition name="fade" mode="out-in">
      <div v-show="showTarget" class="tw-my-8 tw-w-full tw-px-4 tw-py-6 tw-rounded tw-bg-white tw-shadow-card">
        <div ref="chart" class="tw-w-full tw-h-28" />
      </div>
    </transition>
    <DashboardSurveys />
  </div>
</template>

<script>
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'

import PageHeader from '@/components/PageHeader.vue'
import DashboardSurveys from '@/components/DashboardSurveys.vue'

import { mapGetters } from 'vuex'
import { getCollaboratorTarget } from '@/services/organization'

export default {
  name: 'Dashboard',
  components: {
    PageHeader,
    DashboardSurveys
  },
  data () {
    return {
      root: null,
      showTarget: false
    }
  },
  computed: {
    ...mapGetters('account', ['collaborator'])
  },
  mounted () {
    this.renderChart()
  },
  beforeDestroy () {
    this.disposeChart()
  },
  methods: {
    async renderChart () {
      try {
        const response = await getCollaboratorTarget(this.collaborator?.id)
        const targets = response.data
        if (!targets) return

        this.showTarget = true

        const colors = [
          am5.color(0xff0000),
          am5.color(0xf6d32b),
          am5.color(0x19d228)
        ]

        // Create a new root
        const root = am5.Root.new(this.$refs.chart)

        // Set theme
        root.setThemes([
          am5themes_Animated.new(root)
        ])

        // Create chart
        const chart = root.container.children.push(
          am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: 'none',
            wheelY: 'none',
            paddingLeft: 0,
            paddingRight: 30
          })
        )

        // Create axes
        const yAxis = chart.yAxes.push(
          am5xy.CategoryAxis.new(root, {
            categoryField: 'category',
            renderer: am5xy.AxisRendererY.new(root, {
            // To render the axis lines
              strokeOpacity: 1,
              strokeWidth: 2
            })
          })
        )

        yAxis.data.setAll([{ category: 'Target' }])

        const xRenderer = am5xy.AxisRendererX.new(root, {
        // To render the axis lines
          strokeOpacity: 1,
          strokeWidth: 2
        })
        xRenderer.grid.template.set('forceHidden', true)
        xRenderer.ticks.template.setAll({
        // To render the ticks
          strokeOpacity: 1,
          strokeWidth: 2,
          visible: true
        })

        const xAxis = chart.xAxes.push(
          am5xy.ValueAxis.new(root, {
            renderer: xRenderer,
            min: 0,
            max: targets.year_target
          })
        )

        // Add gradient
        const rangeDataItem = xAxis.makeDataItem({
          value: 0,
          endValue: targets.year_target
        })
        xAxis.createAxisRange(rangeDataItem)
        rangeDataItem.get('axisFill').setAll({
          visible: true,
          fillOpacity: 1
        })
        const stops = colors.map(color => { return { color } })
        const linearGradient = am5.LinearGradient.new(root, {
          rotation: 0,
          stops
        })
        rangeDataItem.get('axisFill').set('fillGradient', linearGradient)

        // Create turnover series
        const turnover = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: 'value',
            categoryYField: 'category',
            fill: am5.color(0x000000),
            stroke: am5.color(0x000000),
            tooltip: am5.Tooltip.new(root, {
              labelText: 'Huidige omzet: €{valueX}'
            })
          })
        )
        turnover.columns.template.setAll({
          height: am5.percent(35)
        })
        turnover.data.setAll([{ category: 'Target', value: targets.turnover }])

        // Create year target
        const targetToday = chart.series.push(
          am5xy.StepLineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: 'value',
            categoryYField: 'category',
            fill: am5.color(0x000000),
            stroke: am5.color(0x000000),
            noRisers: true,
            stepWidth: am5.p100,
            tooltip: am5.Tooltip.new(root, {
              labelText: 'Verwacht resultaat: €{valueX}'
            })
          })
        )
        targetToday.strokes.template.set('strokeWidth', 2)
        targetToday.data.setAll([{ category: 'Target', value: targets.year_target_today }])

        // Create total target
        const yearTarget = chart.series.push(
          am5xy.StepLineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: 'value',
            categoryYField: 'category',
            fill: am5.color(0x000000),
            stroke: am5.color(0x000000),
            noRisers: true,
            stepWidth: am5.p100,
            tooltip: am5.Tooltip.new(root, {
              labelText: 'Totale target: €{valueX}'
            })
          })
        )
        yearTarget.data.setAll([{ category: 'Target', value: targets.year_target }])

        // Add cursor
        const cursor = chart.set('cursor', am5xy.XYCursor.new(
          root, {
            behavior: 'none'
          })
        )
        cursor.lineY.set('visible', false)
        cursor.lineX.set('visible', false)

        // Make stuff animate on load
        chart.appear(1000, 100)

        turnover.appear()
        yearTarget.appear()
        targetToday.appear()

        this.root = root
      } catch (error) {
        if (error.response.status === 404) this.showTarget = false
        else console.error(error)
      }
    },
    disposeChart () {
      if (this.root) {
        this.root.dispose()
      }
    }
  }
}
</script>
