var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.surveysAverage)?_c('div',{staticClass:"tw-my-8 tw-p-4"},[_c('div',{staticClass:"tw-pb-4 tw-flex tw-gap-4 tw-items-center"},[_c('img',{staticClass:"tw-h-10",attrs:{"alt":"score","src":_vm.scores[Math.floor(_vm.surveysAverage)].icon}}),_c('h2',{staticClass:"tw-my-0"},[_vm._v(" Gemiddelde score: "+_vm._s(_vm.surveysAverage)+" ("+_vm._s(_vm.surveys.count)+" "+_vm._s(_vm.surveys.count === 1 ? 'CSAT-bevraging' : 'CSAT-bevragingen')+") ")])]),_c('DataTable',_vm._b({attrs:{"loading":_vm.loading,"headers":_vm.headers,"infinite-scroll":true},on:{"infiniteLoad":_vm.infiniteLoad},scopedSlots:_vm._u([{key:"item-score",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"tw-h-5",attrs:{"title":("Score: " + (item.score)),"alt":("Score: " + (item.score)),"src":_vm.scores[item.score].icon}})]}},{key:"item-completed_on",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date-day")(item.completed_on))+" ")]}},{key:"item-property",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'PropertyDetails', params: { id: item.property.id } },"target":"_blank"}},[_vm._v(" "+_vm._s(item.property.reference)+" ")])]}},{key:"item-contact",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'ContactDetails', params: { id: item.contact.id } },"target":"_blank"}},[_vm._v(" "+_vm._s(item.contact.display_name)+" ")])]}},{key:"item-collaborator_comments",fn:function(ref){
var item = ref.item;
return [_c('SurveysAddEditComment',{attrs:{"survey":item},on:{"submitted":_vm.commentSubmitted},scopedSlots:_vm._u([{key:"summary",fn:function(){return [_vm._v(" "+_vm._s(item.collaborator_comments.length)+" "+_vm._s(item.collaborator_comments.length === 1 ? 'opmerking' : 'opmerkingen')+" ")]},proxy:true}],null,true)})]}}],null,false,3606253936)},'DataTable',_vm.surveys,false))],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }